import React from 'react'

function ThankYou() {
    return (
        <div className="container">

            <h2>Thank you :) </h2>
            <p> I will reply to you as soon as possible</p>
            
        </div>
    )
}

export default ThankYou
