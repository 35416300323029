import Hero from './Hero';
import './header.style.css'


const Header = () => (
  <div className='header'> 
    <Hero />
  </div>
)

export default Header;